import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';

import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import Search from './Search';


const styles = (theme) => ({
  appBar: {
    zIndex: 1201,
    backgroundColor: '#607d8b',
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  menuButton: {
    // marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },  
});

function getTitle() {
  return window.location.host.indexOf('house') !== -1 ? 'IntraHouse' : 'IntraSCADA';
}

function MainAppBar({ history, classes, lang, handleDrawerToggle, handleChangeLang }) {
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap>
          {getTitle()}
        </Typography>
        <Search.SectionMobile history={history} lang={lang} onChangeLang={handleChangeLang} />
        <Search.SectionDesktop history={history} lang={lang} onChangeLang={handleChangeLang} />
      </Toolbar>
    </AppBar>
  );
}

export default withStyles(styles)(MainAppBar);
